
// @ts-nocheck
import { SkeletonLine } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  components: {
    SkeletonLine,
  },
  props: {
    companyResultIsError: Boolean,
    imageResult: Object,
    loaded: Boolean,
  },
  computed: {
    companyLogo() {
      return this.imageResult?.variants?.[0]?.url;
    },
  },
  data() {
    return {
      imgErrored: false,
    };
  },
  methods: {
    onError() {
      this.imgErrored = true;
    },
  },
});
