
// @ts-nocheck
import { log, Level } from "num-easy-log";
import { Card, SkeletonLine } from "@num/component-library";
import Vue from "vue";

import { STAGE } from "@/app.config";

import ListingItemLogo from "@/components/ListingItemLogo.vue";

import { Image } from "@/types/index";

const getLogLevel = () => {
  switch (STAGE) {
    case "local":
      return Level.warn;
    case "test":
      return Level.debug;
    case "staging":
      return Level.warn;
    default:
      return Level.silent;
  }
};
const logLevel = getLogLevel();

log.setLevel(logLevel);
log.setConsoleOnly(true);

export default Vue.extend({
  name: "ListingItem",
  components: {
    Card,
    ListingItemLogo,
    SkeletonLine,
  },
  props: {
    companyDomain: {
      default() {
        return "";
      },
      type: String,
    },
    jsonCompanyResult: {
      default() {
        return {};
      },
      type: Object,
    },
  },
  computed: {
    companyName(): string {
      return this.jsonCompanyResult?.name;
    },
    companyResultIsError(): boolean {
      return this.jsonCompanyResult instanceof Error;
    },
    companySlogan(): string {
      return this.jsonCompanyResult?.slogan;
    },
    imageResult(): Image | undefined {
      return this.jsonCompanyResult?.images?.[0];
    },
    link() {
      return {
        name: "company",
        params: {
          companyDomain: this.companyDomain,
        },
      };
    },
    linkDisabled(): boolean {
      return !this.companyName;
    },
  },
});
