
// @ts-nocheck
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import Container from "@/components/Container.vue";
import Hero from "@/components/Hero.vue";
import ListingItem from "@/components/ListingItem.vue";

export default Vue.extend({
  name: "Category",
  components: {
    Container,
    Hero,
    ListingItem,
  },
  data() {
    return {
      companyCategories: null,
    };
  },
  computed: {
    ...mapGetters(["countryCode", "countryCodeDisplay", "countryHomePath"]),
    ...mapState(["jsonCompanyResults"]),
    allCompanies() {
      return [
        ...this.category.companies.featured,
        ...this.category.companies.other,
      ].sort();
    },
    category() {
      return this.companyCategories?.find((c) => c.id === this.categoryId);
    },
    categoryId(): string {
      return this.$route.params.categoryId;
    },
  },
  watch: {
    countryCode: {
      handler(newVal) {
        if (!newVal) return;
        import(`@/static/${newVal}/companies.json`).then((module) => {
          this.companyCategories = module.default;
        });
      },
      immediate: true,
    },
  },
});
